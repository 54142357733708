import { SelfExclusionType } from '~/@types/generated/backend/graphql-schema-types'

export default defineNuxtRouteMiddleware(async ({ name }) => {
  if (import.meta.server) return
  const { isLoggedIn } = useAuth()
  if (!isLoggedIn.value) throw create404Error()

  const payload = await useUserStatusLazyQuery({
    fetchPolicy: 'cache-first',
  }).load()

  const type = payload && payload?.player?.selfExclusion?.type
  if (!type) throw create404Error()

  if (type === SelfExclusionType.CoolOff && name !== 'cool-off') {
    return navigateTo('/cool-off')
  }

  if (type === SelfExclusionType.SelfExclusion && name !== 'self-excluded') {
    return navigateTo('/self-excluded')
  }
})
